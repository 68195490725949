import React, { createContext } from 'react';

type UserContextType = {
  pseudo?: string;
  setPseudo?: React.Dispatch<React.SetStateAction<string>>;
  chatId?: string;
  setChatId?: React.Dispatch<React.SetStateAction<string>>;
  token?: string | null;
  setToken?: React.Dispatch<React.SetStateAction<string | null>>;
  pseudoOther?: string | null;
  setPseudoOther?: React.Dispatch<React.SetStateAction<string | null>>;
  roomId?: string | null;
  setRoomId?: React.Dispatch<React.SetStateAction<string | null>>;
};

const UserContext = createContext<UserContextType>({});
export default UserContext;
