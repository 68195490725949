const theme = {
  colors: {
    blue: '#2e2d72',
    blue20: 'rgba(46, 45, 114, 0.2)',
    blue30: 'rgba(46, 45, 114, 0.3)',
    blue40: 'rgba(46, 45, 114, 0.4)',
    blue60: 'rgba(46, 45, 114, 0.6)',
    blue70: 'rgba(46, 45, 114, 0.7)',
    blue80: 'rgba(46, 45, 114, 0.8)',
    blue7: 'rgba(46, 45, 114, 0.07)',
    red: '#e24136',
    white: '#ffffff',
    pink: '#fce8e2',
    darkpink: '#f1d3ca',
    pink70: 'rgba(252, 232, 226, 0.7)',
    grey: '#b6bbc4',
    darkgrey: '#7d7d7d',
    shadegrey: '#aaacaf',
    lightgrey: '#ced1d1',
    green: '#58b5b1'
  }
};

export default theme;
