import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Loader from './loader/Loader';
const Cgu = lazy(() => import('./cgu/CguPage'));
const Mentions = lazy(() => import('./mentions/Mentions'));
const Login = lazy(() => import('./login/Login'));
const Chat = lazy(() => import('./chat/Chat'));

interface IProps {
  token?: string;
  roomId?: string;
  pseudoOther?: string;
}

const Routes: React.FC<IProps> = ({ token, roomId, pseudoOther }) => {
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        {!token && (
          <Switch>
            <Route component={Login} path='/login' />
            <Route component={Cgu} path='/cgu' />
            <Redirect from='/' to='/login' />
          </Switch>
        )}
        {token && (
          <Switch>
            {roomId && pseudoOther ? (
              <Redirect from='/chat-await' to={`/chat`} />
            ) : (
              <Redirect from='/chat' to='/chat-await' />
            )}
            <Route
              render={props => <Chat {...props} wait={true} />}
              path='/chat-await'
            />
            <Route
              render={props => <Chat {...props} wait={false} />}
              path='/chat'
            />
            <Route component={Cgu} path='/cgu' />
            {roomId && pseudoOther ? (
              <Redirect from='/' to={`/chat`} />
            ) : (
              <Redirect from='/' to='/chat-await' />
            )}
          </Switch>
        )}
      </BrowserRouter>
    </Suspense>
  );
};

export default Routes;
