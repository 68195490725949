/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import Routes from './Routes';
import UserContext from './UserContext';
import { initCrypt } from './crypto-worker';
import { ThemeProvider } from 'emotion-theming';
import theme from './theme';
import './fonts.css';

const GenericStyle = css`
  font-family: 'Work Sans';
  color: ${theme.colors.blue};
  overflow-x: hidden;
  body {
    margin: 0;
    color: ${theme.colors.blue};
  }

  @keyframes autofill {
    to {
      color: ${theme.colors.blue};
      background: transparent;
    }
  }

  input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
  }
`;

const App: React.FC = () => {
  const [pseudo, setPseudo] = useState<string>('');
  const [chatId, setChatId] = useState<string>('');
  const [roomId, setRoomId] = useState<string | null>(
    localStorage.getItem('roomId')
  );
  const [pseudoOther, setPseudoOther] = useState<string | null>(
    localStorage.getItem('pseudoOther')
  );
  const [token, setToken] = useState<string | null>(
    localStorage.getItem('token')
  );

  useLayoutEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }, []);

  useEffect(() => {
    if (token === null) return;

    if (!localStorage.getItem('publicKey')) initCrypt();
  }, [token]);
  return (
    <div css={GenericStyle} className='App'>
      <ThemeProvider theme={theme}>
        <UserContext.Provider
          value={{
            pseudo,
            setPseudo,
            chatId,
            setChatId,
            token,
            setToken,
            roomId,
            setRoomId,
            pseudoOther,
            setPseudoOther
          }}
        >
          <Routes
            token={token === null ? undefined : token}
            roomId={roomId === null ? undefined : roomId}
            pseudoOther={pseudoOther === null ? undefined : pseudoOther}
          />
        </UserContext.Provider>
      </ThemeProvider>
    </div>
  );
};

export default App;
