import JSEncrypt from 'jsencrypt';

function initCrypt() {
  const crypt = new JSEncrypt({ default_key_size: 2056 });
  const cryptPrivateKey = crypt.getPrivateKey();
  const cryptPublicKey = crypt.getPublicKey();
  localStorage.setItem('publicKey', cryptPublicKey);
  localStorage.setItem('privateKey', cryptPrivateKey);
}

/** Encrypt the provided string with the destination public key */
function encrypt(content) {
  const crypt = new JSEncrypt({ default_key_size: 2056 });
  crypt.setKey(localStorage.getItem(`publicKeyOther`));
  return crypt.encrypt(content);
}

/** Decrypt the provided string with the local private key */
function decrypt(content) {
  const crypt = new JSEncrypt({ default_key_size: 2056 });
  crypt.setKey(localStorage.getItem('privateKey'));
  return crypt.decrypt(content);
}

export { initCrypt, encrypt, decrypt };
